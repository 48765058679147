@tailwind base;
@tailwind components;
@tailwind utilities;


.your-element {
    /* Your initial styles here */
    opacity: 0; /* Initially, set the opacity to 0 */
    transform: translateY(20px); /* Optional: Initial position offscreen */
    transition: opacity 1s, transform 1s;
  }
  
  .fade {
    opacity: 1; /* When the element comes into view, fade in */
    transform: translateY(0); /* Optional: Reset the element's position */
  }

  body {
    font-family: 'Inter', sans-serif;
  }
  li{
    list-style: none;
  }
  a.no-underline {
    text-decoration: none;
  }
  a {
    text-decoration: none;
  }
    